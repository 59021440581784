import { Spin } from 'antd'

const Loading = () => {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Spin />
    </div>
  )
}

export default Loading
